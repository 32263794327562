import styled from '@emotion/styled';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Col, Row } from 'antd';
import DOMPurify from 'isomorphic-dompurify';
import React, { useCallback } from 'react';

import { InternalContainer, StyledDisplay, StyledHtmlContainer, StyledImage } from './section-styles';

import { programShortName } from '../../siteContent';
import theme from '../../theme';
import Button from '../button';
import TechnologyPartners from '../common/technology-partners';

type SectionProps = {
  tagLine: string;
  description: string;
  imageUrl?: string;
  cta: string;
  ctaLink: string;
  handleClick: (link: string) => void;
};

const HeroSection = styled.section`
  overflow: hidden;
  position: relative;
  margin-top: 24px;
`;

const HeroTagLine = styled(StyledDisplay)`
  letter-spacing: -1px;
  line-height: 60px;
  padding-bottom: 16px;
`;

const HeroDescription = styled(StyledHtmlContainer)`
  margin-bottom: 30px;
  @media (max-width: ${theme.screenXsMax}) {
    padding-bottom: 80px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

const CircleButtonContainer = styled.div`
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #f0f0f0;
  @media (max-width: ${theme.screenXsMax}) {
    display: none;
  }
`;

const CircleButtonLabel = styled.span`
  font-size: 18px;
  padding-right: 25px;
`;

const CircleButton = styled(Button)`
  && {
    flex-shrink: 0;
    border-radius: 50%;
    height: 58px;
    width: 58px;
    padding: 0;
    background-color: ${theme.colorsPrimaryBlue};
  }
`;

const DownArrowIcon = styled(ArrowDownwardIcon)`
  color: white;
  vertical-align: middle;
`;

export default function HeroComponent({
  tagLine,
  description,
  imageUrl,
  cta,
  ctaLink,
  handleClick,
}: SectionProps) {
  const handleOnClick = useCallback(() => {
    handleClick(ctaLink);
  }, [ctaLink, handleClick]);

  return (
    <InternalContainer>
      <HeroSection>
        <Row align="middle" justify="space-between">
          <Col lg={{ span: 10 }}>
            <HeroTagLine>{tagLine}</HeroTagLine>
            <HeroDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
            <CircleButtonContainer>
              <CircleButtonLabel>{cta}</CircleButtonLabel>
              <CircleButton type="primary-blue" onClick={handleOnClick}>
                <DownArrowIcon titleAccess="Scroll down" />
              </CircleButton>
            </CircleButtonContainer>
            <TechnologyPartners headingStyles={`color: ${theme.colorsBlack};`} />
          </Col>
          {imageUrl && (
            <Col lg={{ span: 12 }}>
              <StyledImage src={imageUrl} alt={`${programShortName} Hero`} />
            </Col>
          )}
        </Row>
      </HeroSection>
    </InternalContainer>
  );
}
